import React from "react"
import { Link } from "gatsby"
import Layout from "src/components/layout"
import SEO from "src/components/seo"
import Breadcrumb from "src/components/Breadcrumb"

import { Container, Col, Row } from "react-bootstrap"

// import { graphql } from "gatsby"

import "src/assets/less/blog.less"

const Blog = ( data ) => {
  const blogPost = data.pageContext
  const date = new Date(blogPost.date);

  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

  const metadataTitle = blogPost.metadata.metadataTitle ? blogPost.metadata.metadataTitle : "Adlai Abdelrazaq"
  const metadataDescription = blogPost.metadata.metadataDescription ? blogPost.metadata.metadataDescription : "I am a deveoloper"
  const metadataImg = blogPost.metadata.metadataImage ? blogPost.metadata.metadataImage.localFile.url : ''
  return (
    <Layout page="blog">
      <SEO title={metadataTitle}
           description={metadataDescription}
           imageURL={metadataImg} />
      <Container>
        <Row>
          <Col xs={12} lg={8} sm={10}>
            <div className={`blog-detail`}>
              <Breadcrumb />

              <h1 className="mb-4">
                {blogPost.title}
              </h1>
              <p>Uploaded on {formattedDate}</p>
              
              <div className={`blog-listing-content`} dangerouslySetInnerHTML={{__html: blogPost.content}}>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default Blog